$(function() {

});

(function($) {
    $.fn.ensureVisible = function(callback) {
        var $this = $(this).first();
        var $parent = $this.parent();
        var scrollTop = $parent.scrollTop();
        var scrollBottom = scrollTop + $parent.innerHeight();
        var marginTop = parseInt($this.css('margin-top'));
        var marginBottom = parseInt($this.css('margin-bottom'));
        var top = $this.position().top + scrollTop + marginTop;
        var bottom = top + $this.outerHeight();
        var newPosition = null;

        if (scrollTop > top - marginTop) {
            newPosition = { scrollTop: top - marginTop };
        } else if (scrollBottom < bottom + marginBottom) {
            newPosition = { scrollTop: bottom - $parent.innerHeight() + marginBottom };
        }

        if (newPosition) {
            $parent.animate(newPosition, {
                duration: 200,
                done: callback.bind(this)
            });
        } else {
            setTimeout(callback.bind(this));
        }

        return this;
    };
})(jQuery);

// $('#my_div').myfunction();

function callEnsureVisible(ele, SN) {


    $($(ele)[0]).ensureVisible(function() {
        // Focus it manually since we will preventDefault later.
        // Note: Do not call .focus() directly.
        //       -- otherwise SpatialNavigation can't keep some status.
        // console.log(this);
        SN.focus(this);

        // Resume key event handler.
        SN.resume();
    });
}

// to use
// declare function callEnsureVisible(ele: any, SN: any): any;
// callEnsureVisible(e.target, that.SN)